import { Component, OnInit, Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../service/authentication/authentication.service';
import { Token } from '../model/token';
import { Store, select } from '@ngrx/store';
import { IsValidToken } from '../actions/token.actions';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  currentToken: Token;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private tokenStore: Store<Token>) {

    authenticationService.getCurrentToken().subscribe(token => this.currentToken = token);

  }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean  {
    this.tokenStore.dispatch(IsValidToken());
    if (this.currentToken.isValidToken)
      return true;

    this.authenticationService.logout();

    return false;
  }

}
