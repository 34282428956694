import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ModalAlertService } from '../../modules/modals/alert-window';
import { Router } from '@angular/router';

@Component({
    selector: 'modal-envia-email-component',
    template: '',
})
export class ModalEnviaEmailService {
    bsModalRef: BsModalRef;
  constructor(private modalService: BsModalService) { }

    show(title: string, message: string) {
        const initialState = {
            title: title,
            message: message
        };
      this.bsModalRef = this.modalService.show(ModalEnviaEmailComponent, {
        initialState,
        ignoreBackdropClick: true,
        keyboard: false
      });
        this.bsModalRef.content.closeBtnName = 'Close';
    }
}

@Component({
    selector: 'modal-content',
    template: `<div class="modal-header">
                <h4 class="modal-title text-center">{{title}}</h4>
               </div>
               <div class="modal-body text-center">
                  <p>{{message}}</p>                
               </div>
               <div class="modal-footer" >
              <div class="row" align="center">
                <!--<a class="btn btn-ok"  >Ok</a>-->
                <button type="submit" class="btn btn-ok" (click)="onRedireciona()">Ok</button>
              </div>
            </div>
                    `,
  styles: [`
            .btn-ok {
            background:#075e9c;
              color:#fff !important;
            padding:5px 20px;
              border-radius:20px;
            text-transform: uppercase;
            font-size: 14px;
            font-family: 'Montserrat', sans-serif;         
      }
          `]
})

export class ModalEnviaEmailComponent {   

    constructor(public bsModalRef: BsModalRef,        
      private alertModalService: ModalAlertService,
      private router: Router
  ) { }

  onRedireciona() {
    this.bsModalRef.hide();
    this.router.navigate(['login']);
  }
}
