
import { Injectable } from '@angular/core';
import { CustomHttpClient, IRequestOptions } from './../../providers/CustomHttpClient';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../model/user';
import { Beneficiary } from '../../model/beneficiary';
import { baseApiUrl } from '../../app.config';
import { Store, select } from '@ngrx/store';
import { Token } from '../../model/token';
import { AddUser, ClearUser } from '../../actions/user.actions';
import { AddToken, ClearToken, IsValidToken } from '../../actions/token.actions';
import { AddBeneficiary, ClearBeneficiary } from '../../actions/beneficiary.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  user$: Observable<User>;
  token$: Observable<Token>;
  beneficiary$: Observable<Beneficiary>;

  constructor(private http: CustomHttpClient,
              private userStore: Store<User>,
              private tokenStore: Store<Token>,
              private beneficiaryStore: Store<Beneficiary>
  ) {

    this.user$ = this.userStore.pipe(select('user'));
    this.token$ = this.tokenStore.pipe(select('token'));
    this.beneficiary$ = this.beneficiaryStore.pipe(select('beneficiary'));
  }


  storeData(user: User, token: Token, beneficiary: Beneficiary) {
    this.tokenStore.dispatch(AddToken(token))
    this.userStore.dispatch(AddUser(user));
    this.beneficiaryStore.dispatch(AddBeneficiary(beneficiary));
  }
   
  getCurrentToken(){
    return this.token$;
  }

  getCurrentUser() {
    return this.user$;
  }

  getCurrentBeneficiary() {
    return this.beneficiary$;
  }

  logout() {
    this.tokenStore.dispatch(ClearToken())
    this.userStore.dispatch(ClearUser());
    this.beneficiaryStore.dispatch(ClearBeneficiary());
  }

  login(user: User) {
    return new Promise((resolve, reject) => {
      this.http.Post(`${baseApiUrl}api/authentication/Login`, user).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  insert(user: any) {

    return new Promise((resolve, reject) => {
      this.http.Post(`${baseApiUrl}api/authentication/Insert`, user).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  forgotPassword(user: User) {

    return new Promise((resolve, reject) => {
      this.http.Post(`${baseApiUrl}api/authentication/ForgotPassword`, user).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  update(beneficiary: Beneficiary) {
    return new Promise((resolve, reject) => {
      this.http.Post(`${baseApiUrl}api/authentication/Update`, beneficiary).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  changePassword(user: User) {
    return new Promise((resolve, reject) => {
      this.http.Post(`${baseApiUrl}api/authentication/ChangePassword`, user).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }


  securityKey(key: string) {
    return this.http.Get(`${baseApiUrl}api/authentication/SecurityKey/` + key);
  }

  beneficiaryByCpf(cpf: string) {
    return this.http.Get(`${baseApiUrl}api/authentication/BeneficiaryByCpf/` + cpf);
  }

  ExistsRegister(cpf: string) {
    
    return this.http.Get(`${baseApiUrl}api/authentication/ExistsRegister/` + cpf);
  }

  

  
}
