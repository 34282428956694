
import { Injectable } from '@angular/core';
import { CustomHttpClient, IRequestOptions } from './../../providers/CustomHttpClient';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Product } from '../../model/product';
import { Router } from '@angular/router';
import { baseApiUrl } from '../../app.config';


@Injectable({
  providedIn: 'root'
})
export class ProductService {
  router: Router;

  constructor(private http: CustomHttpClient, router: Router)
  {
    this.router = router;
  }

  public ReadProducts(term: string, uf: string) {
    return this.http.Get(`${baseApiUrl}api/product/ReadProducts/` + term + "/" + uf);
  }

  public GetProduct(skuId: number) {
    return this.http.Get(`${baseApiUrl}api/product/GetProduct/` + skuId);
  }

  public GetProductPrice(skuId: number, uf: string) {
    return this.http.Get(`${baseApiUrl}api/product/GetProductPrice/` + skuId + "/" + uf);
  }
 
}
