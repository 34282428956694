
import { Injectable } from '@angular/core';
import { CustomHttpClient, IRequestOptions } from './../../providers/CustomHttpClient';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { baseApiUrl } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: CustomHttpClient) { }

  ngOnInit() {    
    
  }

  getState(filter: string) {

    return new Promise((resolve, reject) => {
      this.http.Get(`${baseApiUrl}api/home/GetState`).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
    
  }

 
}
