import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from './service/data/data.service';
import { User } from './model/user';
import { LoginComponent } from './pages/login/login.component';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Token } from './model/token';
import { State } from './model/state';
import { AuthenticationService } from './service/authentication/authentication.service';
import { debounceTime } from 'rxjs/operators';
import { Beneficiary } from './model/beneficiary';
import { ClearState } from './actions/state.actions';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  idPlano: number;
  title = 'Portal Cupom de Desconto';
  footerPosition = 'relative';
  user: User = new User();
  isLogado: boolean = false;
  idUser: any;

  state$: Observable<State>;
  beneficiary$: Observable<Beneficiary>;
  loggedUser: Observable<User>;
  currentState: State;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private stateStore: Store<State>,
    private userStore: Store<User>,
    private beneficiaryStore: Store<Beneficiary>) {

    this.state$ = stateStore.pipe(select('state'))
    this.loggedUser = userStore.pipe(select('user'))

    this.beneficiary$ = beneficiaryStore.pipe(select('beneficiary'))

  }

  ngOnInit() {
    this.state$.pipe(debounceTime(100)).subscribe(state => this.currentState = state);
  }

  ngAfterViewInit() {

  }

  changeState(){
    this.stateStore.dispatch(ClearState());
  }

  logout() {
    this.authenticationService.logout();
  }
  searchClickGlobal(e: any) {
    var pesquisa = $("#searchGlobal").val();
    var estado;

    if (e.which == 13) {
      e.preventDefault();

      this.state$.subscribe((state) => {
        estado = state.name
      });

      this.router.navigate([`/vitrine/${pesquisa}/${estado}`]);

    }
  }

}
