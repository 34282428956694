import { Component, OnInit, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../service/authentication/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { User } from '../../model/user';
import { ModalGenenericService } from '../../modules/modals/generic-window.module';
import { Beneficiary } from '../../model/beneficiary';
import { ModalAlertService } from '../../modules/modals/alert-window';
import { LayoutComponent } from '../layout/layout.component';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.css']
})
export class AlterarSenhaComponent implements OnInit {
  beneficiary: Beneficiary = new Beneficiary();
  user: User = new User();
  passwordPattern = "/([A-Za-z]{1,}).*(\d{1,}).*|(\d{1,}).*([A-Za-z]{1,}).*/";
  show: boolean;
  mensagem: string;
  showRedefinir: boolean;
  mensagemRedefinir: string;
  hashKeyNotFound: boolean = true;
  repeatPassword: string;

  perfilFormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, GenericValidator.validatePassword()]),
    repeatPassword: new FormControl('', [Validators.required]),
  });

  constructor(private authenticationService: AuthenticationService,
    private route: Router,
    private activatedRoute: ActivatedRoute,    
    private modalGeneric: ModalGenenericService,
    private modalAlert: ModalAlertService) { }

  ngOnInit() {
    LayoutComponent.ShowHeaderInput('alterar-senha');
    this.show = false;
    localStorage.removeItem('userId');

    this.activatedRoute.params.subscribe(params => {
      if (Object.getOwnPropertyNames(params).length > 0) {
        this.authenticationService.securityKey(params.hash).subscribe((res: any) => {
          var result = JSON.parse(res.result);

          if (result.success) {
            //Adicionado no dataService o usuario atual do sistema
            this.user.id = result.data.id;
            this.user.securityKey = result.data.securityKey;
            this.user.login = result.data.login;
            this.user.cpf = result.data.cpf;
          }
          else {
            this.openModalNotFound();
          }

        });

      }
    });

  }

  openModalNotFound() {
    this.modalGeneric.show('Redefinição de senha', 'Link de alteração de senha vencida! clique em OK para solicitar uma nova redefinição.', 'esqueciMinhaSenha');
  }

  openModalSuccess() {
    this.modalGeneric.show('Redefinição de senha', 'Senha Alterada com sucesso', 'login');
  }

  openModalAlert() {
    this.modalAlert.show('Alerta', 'Ocorreu um erro na solicitação tente novamente mais tarde!');
  }

  onPasswordReset() {

    this.authenticationService.changePassword(this.user).then((res: any) => {
      var result = JSON.parse(res.result);

      if (result.success) {
        this.openModalSuccess();
      }
      else {
        console.error(result.error);
        this.openModalAlert();
      }

    },
      (err: any) => {
        console.error(err);
        this.openModalAlert();
      });
  }

  public cssObrigatorio(nomecampo) {
    if (this.perfilFormGroup.controls[nomecampo].invalid && (this.perfilFormGroup.controls[nomecampo].touched || this.perfilFormGroup.controls[nomecampo].dirty)) {
      $("#" + nomecampo).css("border-color", "#a94442");
    }
    if (this.perfilFormGroup.controls[nomecampo].valid) {
      $("#" + nomecampo).css("border-color", "#003a75");
    }
  }

  public ativaTooltipSenha(component) {

    var ativa = false;

    var senhaOriginal = this.perfilFormGroup.controls["password"].value;
    var senhaRepetida = this.perfilFormGroup.controls["repeatPassword"].value;

    if (senhaOriginal != "" && senhaRepetida != "") {
      if (senhaOriginal != senhaRepetida) {
        ativa = true;
      }
    }
    this.ativaTooltip(ativa, component);
  }

  public ativaTooltipObrigatorio(nomecampo, component) {

    var ativa = this.perfilFormGroup.controls[nomecampo].invalid;
    var campoObrigatorioerror = false;
    if (ativa) {
      campoObrigatorioerror = this.perfilFormGroup.controls[nomecampo].errors.required;
      campoObrigatorioerror = (campoObrigatorioerror !== null && campoObrigatorioerror) ? true : false;
    }

    ativa = ativa && campoObrigatorioerror;

    this.ativaTooltip(ativa, component);
  }

  public ativaTooltipcampos(nomecampo, component) {

    var ativa = this.perfilFormGroup.controls[nomecampo].invalid;
    var patterninvalido = false;
    var senhainvalida = false;
    if (ativa) {
      senhainvalida = this.perfilFormGroup.controls[nomecampo].errors.passwordNotValid;
      patterninvalido = this.perfilFormGroup.controls[nomecampo].errors.pattern;
      senhainvalida = (senhainvalida !== null && senhainvalida) ? true : false;
      patterninvalido = (patterninvalido !== null && patterninvalido) ? true : false;
    }

    ativa = ativa && (patterninvalido || senhainvalida);

    this.ativaTooltip(ativa, component);
  }

  public ativaTooltip(ativa, component) {

    var acao1 = ativa ? 'enable' : 'hide';
    var acao2 = ativa ? 'show' : 'disable';
    $(component).tooltip(acao1);
    $(component).tooltip(acao2);
  }

}
export class GenericValidator {
  constructor() { }

  /**
  * Valida se a data é valida
  */
  static validateDate() {
    return (control: AbstractControl): Validators => {
      var RegExPattern = /^((((0?[1-9]|[12]\d|3[01])[\.\-\/](0?[13578]|1[02])      [\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|[12]\d|30)[\.\-\/](0?[13456789]|1[012])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|1\d|2[0-8])[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|(29[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00)))|(((0[1-9]|[12]\d|3[01])(0[13578]|1[02])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|[12]\d|30)(0[13456789]|1[012])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|1\d|2[0-8])02((1[6-9]|[2-9]\d)?\d{2}))|(2902((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00))))$/;
      if (control.value.toString()) {
        if (!((control.value.match(RegExPattern)) && (control.value != ''))) {
          return { dataNotValid: true };
        }
        else
          return null;
      }

      return null;
    };
  }


  /**
   * Valida se o CPF é valido
  */
  static isValidCpf() {
    return (control: AbstractControl): Validators => {

      if (control.value) {
        const cpf = control.value.toString().replace(/\.|\-/g, '');

        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        if (cpf.length < 11) {
          return null;
        }

        for (i = 0; i < cpf.length - 1; i++) {
          if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpf.substring(0, 9);
          digits = cpf.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfNotValid: true };
          }
          numbers = cpf.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfNotValid: true };
          }
          return null;
        } else {
          return { cpfNotValid: true };
        }
      }
      return null;
    };
  }

  public static passwordPattern = /([A-Za-z]{1,}).*(\d{1,}).*|(\d{1,}).*([A-Za-z]{1,}).*/;
  static validatePassword() {
    return (control: AbstractControl): Validators => {

      let result = {};

      if (control.value != null) {
        const password = control.value.toString().replace(/\.|\-/g, '');
        if (password && password.length > 0) {
          if (!password.match(this.passwordPattern) || password.length < 8) {
            return { passwordNotValid: true };
          }
        }
      }

      return null;

    }
  }

}
