import { Component, OnInit, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../service/authentication/authentication.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ModalEnviaEmailService } from '../../modules/modals/envia-email-window';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { User } from '../../model/user';
import { LayoutComponent } from '../layout/layout.component';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './esqueci-minha-senha.component.html',
  styleUrls: ['./esqueci-minha-senha.component.css']
})
export class EsqueciMinhaSenhaComponent implements OnInit {
  user: User = new User();
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
  cpfPattern = "^[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}$";
  show: boolean;
  mensagem: string;
  showRedefinir: boolean;
  mensagemRedefinir: string;
  errorCpf: boolean = false;
  errorEmail: boolean = false;
  notFoundCpf: boolean = false;
  conflictEmail: boolean = false;

  esqueciMinhaSenhaFormGroup = new FormGroup({
    cpf: new FormControl('', [Validators.required, GenericValidator.isValidCpf()]),
    email: new FormControl('', [Validators.pattern(this.emailPattern)]),
  });

  constructor(private authenticationService: AuthenticationService,
    private enviaEmailModalService: ModalEnviaEmailService,
    private route: Router) { }

  ngOnInit() {
    LayoutComponent.ShowHeaderInput('esqueci-minha-senha');
    this.show = false;
    localStorage.removeItem('idUser');
  }

  public ShowSearchBar() {
    $("#searchBar").css('visibility', 'visible');
  }

  public cssObrigatorio(nomecampo, forceInvalid = false) {
    if (this.esqueciMinhaSenhaFormGroup.controls[nomecampo].invalid && (this.esqueciMinhaSenhaFormGroup.controls[nomecampo].touched || this.esqueciMinhaSenhaFormGroup.controls[nomecampo].dirty) || forceInvalid) {
      $("#" + nomecampo).css("border-color", "#a94442");
    }
    if (this.esqueciMinhaSenhaFormGroup.controls[nomecampo].valid && !forceInvalid) {
      $("#" + nomecampo).css("border-color", "#003a75");
    }
  }

  public ativaTooltipObrigatorio(nomecampo, component) {

    var ativa = this.esqueciMinhaSenhaFormGroup.controls[nomecampo].invalid;
    var campoObrigatorioerror = false;
    if (ativa) {
      campoObrigatorioerror = this.esqueciMinhaSenhaFormGroup.controls[nomecampo].errors.required;
      campoObrigatorioerror = (campoObrigatorioerror !== null && campoObrigatorioerror) ? true : false;
    }

    ativa = ativa && campoObrigatorioerror;

    this.ativaTooltip(ativa, component);
  }

  public ativaTooltipcampos(nomecampo, component) {

    var ativa = this.esqueciMinhaSenhaFormGroup.controls[nomecampo].invalid;
    var cpfinvalido = false;
    var patterninvalido = false;

    if (ativa) {
      cpfinvalido = this.esqueciMinhaSenhaFormGroup.controls[nomecampo].errors.cpfNotValid;
      patterninvalido = this.esqueciMinhaSenhaFormGroup.controls[nomecampo].errors.pattern;
      cpfinvalido = (cpfinvalido !== null && cpfinvalido) ? true : false;
      patterninvalido = (patterninvalido !== null && patterninvalido) ? true : false;
    }

    ativa = ativa && (cpfinvalido || patterninvalido);

    if (ativa) {
      var componentAux = (this.errorCpf) ? document.querySelector('#notFoundCpf') : document.querySelector('#conflictEmail');
      this.errorCpf = false;
      this.errorEmail = false;
      this.ativaTooltip(!ativa, componentAux);
    }

    this.ativaTooltip(ativa, component);
  }

  public ativaTooltip(ativa, component) {

    var acao1 = ativa ? 'enable' : 'hide';
    var acao2 = ativa ? 'show' : 'disable';
    $(component).tooltip(acao1);
    $(component).tooltip(acao2);
  }

  public OpenModal() {
    this.enviaEmailModalService.show("Esqueci Minha Senha", "Você receberá uma mensagem em seu e-mail cadastrado. Siga as instruções para recuperar a sua senha.");
  }

  onReset() {
    var obj = new User();
    obj.cpf = this.user.cpf.replace(/\.|\-/g, '');
    obj.email = this.user.email;

    this.notFoundCpf = false;
    this.conflictEmail = false;

    this.authenticationService.forgotPassword(obj).then((res: any) => {
      var result = JSON.parse(res.result);


      if (result.success) {
        this.OpenModal();
      }
      else {
        if (result.error.code == 404) {
          this.errorCpf = true;
          this.cssObrigatorio('cpf', true);
          this.ativaTooltip(true, document.querySelector('#notFoundCpf'));
          this.notFoundCpf = true;
        }
        else if (result.error.code == 409) {
          this.errorEmail = true;
          this.cssObrigatorio('email', true);
          this.ativaTooltip(true, document.querySelector('#conflictEmail'));
          this.conflictEmail = true;
        }
      }

    });
  }
}
export class GenericValidator {
  constructor() { }

  /**
  * Valida se a data é valida
  */
  static validateDate() {
    return (control: AbstractControl): Validators => {
      var RegExPattern = /^((((0?[1-9]|[12]\d|3[01])[\.\-\/](0?[13578]|1[02])      [\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|[12]\d|30)[\.\-\/](0?[13456789]|1[012])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|1\d|2[0-8])[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|(29[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00)))|(((0[1-9]|[12]\d|3[01])(0[13578]|1[02])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|[12]\d|30)(0[13456789]|1[012])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|1\d|2[0-8])02((1[6-9]|[2-9]\d)?\d{2}))|(2902((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00))))$/;
      if (control.value.toString()) {
        if (!((control.value.match(RegExPattern)) && (control.value != ''))) {
          return { dataNotValid: true };
        }
        else
          return null;
      }

      return null;
    };
  }


  /**
   * Valida se o CPF é valido
  */
  static isValidCpf() {
    return (control: AbstractControl): Validators => {

      if (control.value) {
        const cpf = control.value.replace(/\.|\-/g, '');

        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        if (cpf.length < 11) {
          return null;
        }

        for (i = 0; i < cpf.length - 1; i++) {
          if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpf.substring(0, 9);
          digits = cpf.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfNotValid: true };
          }
          numbers = cpf.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfNotValid: true };
          }
          return null;
        } else {
          return { cpfNotValid: true };
        }
      }
      return null;
    };
  }
}
