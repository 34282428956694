import { ActiveIngredients } from "./active-ingredients";
import { ProductDiscount } from "./product-discount";
import { Chain } from "./chain";

export class Product {
  productName: string;
  presentationId: number;
  activeIngredients: Array<ActiveIngredients>;
  standardDiscounts: Array<number>;
  chains: Array<Chain>;
  presentationName: string;
  industryName: string;
  lowestPrice: string;
}

