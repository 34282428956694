import { AuthenticationService } from './service/authentication/authentication.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxMaskModule } from 'ngx-mask';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { CustomHttpClient, CustomHttpClientCreator } from './providers/CustomHttpClient';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';


 
import { NgxUploaderModule } from 'ngx-uploader';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule, Routes } from '@angular/router';
import { routing } from './app.routes';
 
import { AppComponent } from './app.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { HomeComponent, ModalContentStateComponent } from './pages/home/home.component';
import { AuthGuard } from './_guards/AuthGuard';
import { LoginComponent } from './pages/login/login.component';
import { AlterarSenhaComponent } from './pages/alterar-senha/alterar-senha.component';
import { DiscountCouponWindowModule } from './modules/modals/discount.coupon.window.module';
import { DataTableModule } from "angular-6-datatable";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgxPaginationModule } from 'ngx-pagination';
import { HomeService } from './service/home/home.service';
import { EsqueciMinhaSenhaComponent } from './pages/esqueci-minha-senha/esqueci-minha-senha.component';
import { RegistreSeComponent } from './pages/registre-se/registre-se.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ComparacaoPrecoComponent } from './pages/comparacao-precos/comparacaoPreco.component';
import { VitrineComponent } from './pages/vitrine/vitrine.component';
import { ProductService } from './service/product/product.service';
import { UsuarioService } from './service/usuario/usuario.service';
import { VoucherService } from './service/voucher/voucher.service';
import { VoucherComponent } from './pages/voucher/voucher.component';
import { DataService } from './service/data/data.service';
import { UtilService } from './service/util/util.service';
import { StoreModule } from '@ngrx/store';
import { storageSyncMetaReducer } from 'ngrx-store-persist';

import { userReducer, tokenReducer, stateReducer, beneficiaryReducer } from './reducers/';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
  
@NgModule({
  declarations: [
    AppComponent,
    RegistreSeComponent,
    LayoutComponent,
    HomeComponent,
    ModalContentStateComponent,
    LoginComponent,
    AlterarSenhaComponent,
    EsqueciMinhaSenhaComponent,
    DashboardComponent,
    ComparacaoPrecoComponent,
    VitrineComponent,
    VoucherComponent
  ],
  entryComponents: [ModalContentStateComponent],
  imports: [
    BrowserModule,
    RouterModule, 
    FormsModule, 
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgxUploaderModule,
    NgSelectModule,
    PasswordStrengthMeterModule,
    NgxPaginationModule,
    ModalModule.forRoot(), 
    NgxMaskModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DiscountCouponWindowModule.forRoot(),
    TooltipModule.forRoot(),
    routing,
    DataTableModule,
    StoreModule.forRoot({
      user: userReducer,
      token: tokenReducer,
      state: stateReducer,
      beneficiary: beneficiaryReducer
    }, { metaReducers: [storageSyncMetaReducer], })
  ],
  providers: [AuthGuard, HomeService, ProductService, UsuarioService, VoucherService, DataService, AuthenticationService,  UtilService,

    {
    provide: CustomHttpClient,
    useFactory: CustomHttpClientCreator,
    deps: [HttpClient, NgxSpinnerService]
  }],
  bootstrap: [AppComponent]
})


export class AppModule {

  
 }
