import { Component, OnInit, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Usuario } from './../../model/usuario';
import { AuthenticationService } from '../../service/authentication/authentication.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ModalEnviaEmailService } from '../../modules/modals/envia-email-window';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ModalGenenericService } from '../../modules/modals/generic-window.module';
import { DataService } from '../../service/data/data.service';
import { User } from '../../model/user';
import { Token } from '../../model/token';
import { ModalAlertService } from '../../modules/modals/alert-window';
import { ComparacaoPrecoComponent } from '../comparacao-precos/comparacaoPreco.component';
import { VoucherService } from '../../service/voucher/voucher.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LayoutComponent } from '../layout/layout.component';
import { Store } from '@ngrx/store';
import { AddUser, ClearUser } from '../../actions/user.actions';
import { AddToken, ClearToken } from '../../actions/token.actions';
import { Beneficiary } from '../../model/beneficiary';
import { ClearBeneficiary } from '../../actions/beneficiary.actions';
import { UtilService } from '../../service/util/util.service';


declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: User = new User();
  public static beneficiary: any;
  show: boolean;
  mensagem: string;
  showRedefinir: boolean;
  cpfPattern = "^[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}$";
  mensagemRedefinir: string;
  public exibeMsgCpf: boolean = false;
  public exibeMsgCpfGerar: boolean = false;
  attempts: number = 4;
  cpfLogin: string;
  password: string;
  content: string;

  loginFormGroup = new FormGroup({
    cpf: new FormControl('', [Validators.required, GenericValidator.isValidCpf()]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(private authenticationService: AuthenticationService,
    private route: Router,
    private utilService: UtilService,
    private modalGeneric: ModalGenenericService,
    private modalAlert: ModalAlertService,
    private spinner: NgxSpinnerService,
    private userStore: Store<User>,
    private tokenStore: Store<Token>,
    private beneficiaryStore: Store<Beneficiary>) { }

  ngOnInit() {
    LayoutComponent.ShowHeaderInput('login'); 
  }

  private OnEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    this.spinner.show();
  }

  private hideLoader(): void {
    this.spinner.hide();
  }

  logout() {
    this.tokenStore.dispatch(ClearToken());
    this.userStore.dispatch(ClearUser());
    this.beneficiaryStore.dispatch(ClearBeneficiary());
  }

  isValidCPF = () => this.loginFormGroup.controls['cpf'].valid;

  onLogin(e: any) {
    e.preventDefault();

    this.content = '';
    this.user.cpf = this.cpfLogin.replace(/\.|\-/g, '');
    this.user.password = this.password;

    this.authenticationService.login(this.user).then((res: any) => {
      var result = JSON.parse(res.result);

      if (result.success) {

        const { token, user } = result.data;

        this.authenticationService.beneficiaryByCpf(this.user.cpf).subscribe((res: any) => {

          var aux = JSON.parse(res.result);

          if (aux.success) {
            //Store user data login
            this.authenticationService.storeData(user, token, aux.data);
            this.showLoader();
            if (this.utilService.hasVoucher()) {
              this.route.navigate(['voucher']);
            }
            else {
              this.route.navigate(['dashboard', aux.data.id]);
            }
          }
          else {
            console.error(aux.error);
            this.OnEnd();
            this.openModalAlert();
          }

        },
          (err: any) => {
            console.error(err);
            this.openModalAlert();
          });
      }
      else {
        //Diminui as tentativas do usuario;
        if (result.error.code == 404) {
          this.attempts--;
          this.content = `CPF e/ou senha incorreto(s). Você tem mais ${this.attempts} tentativas.`;

          if (this.attempts == 0) {
            this.openModalBlocked();
          }
          //Alteração do bloqueio do botão
        } else if (result.error.code == 403) {
          this.openModalBlocked();
        }
      }
    }, (err: any) => {
      console.error(err);
      this.openModalAlert();
    });
  }

  openModalBlocked() {
    this.utilService.openModalRedirect('Login', 'Acesso bloqueado, solicite uma nova senha', 'esqueciMinhaSenha');
  }
  openModalAlert() {
    this.modalAlert.show('Alerta', 'Ocorreu um erro na solicitação tente novamente mais tarde!');
  }

  openModalVourcherError(message) {
    this.modalAlert.show('Alerta', message);
  }

  public cssObrigatorio(nomecampo, forceInvalid = false) {

    if (this.loginFormGroup.controls[nomecampo].invalid && (this.loginFormGroup.controls[nomecampo].touched || this.loginFormGroup.controls[nomecampo].dirty) || forceInvalid) {
      $("#" + nomecampo).css("border-color", "#a94442");
    }
    if (this.loginFormGroup.controls[nomecampo].valid && !forceInvalid) {
      $("#" + nomecampo).css("border-color", "#003a75");
    }
  }

  public ativaTooltipObrigatorio(nomecampo, component) {

    var ativa = this.loginFormGroup.controls[nomecampo].invalid;
    var campoObrigatorioerror = false;
    if (ativa) {
      campoObrigatorioerror = this.loginFormGroup.controls[nomecampo].errors.required;
      campoObrigatorioerror = (campoObrigatorioerror !== null && campoObrigatorioerror) ? true : false;
    }

    ativa = ativa && campoObrigatorioerror;

    this.ativaTooltip(ativa, component);
  }

  public ativaTooltipcampos(nomecampo, component) {

    var ativa = this.loginFormGroup.controls[nomecampo].invalid;
    var cpfinvalido = false;
    var patterninvalido = false;
    if (ativa) {
      cpfinvalido = this.loginFormGroup.controls[nomecampo].errors.cpfNotValid;
      patterninvalido = this.loginFormGroup.controls[nomecampo].errors.pattern;
      cpfinvalido = (cpfinvalido !== null && cpfinvalido) ? true : false;
      patterninvalido = (patterninvalido !== null && patterninvalido) ? true : false;
    }

    ativa = ativa && (cpfinvalido || patterninvalido);

    this.ativaTooltip(ativa, component);
  }

  public ativaTooltipError(component) {
    this.ativaTooltip((this.attempts < 3), component);
  }

  public ativaTooltip(ativa, component) {

    var acao1 = ativa ? 'enable' : 'hide';
    var acao2 = ativa ? 'show' : 'disable';
    $(component).tooltip(acao1);
    $(component).tooltip(acao2);
  }
}

export class GenericValidator {
  constructor() { }

  /**
  * Valida se a data é valida
  */
  static validateDate() {
    return (control: AbstractControl): Validators => {
      var RegExPattern = /^((((0?[1-9]|[12]\d|3[01])[\.\-\/](0?[13578]|1[02])      [\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|[12]\d|30)[\.\-\/](0?[13456789]|1[012])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|1\d|2[0-8])[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|(29[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00)))|(((0[1-9]|[12]\d|3[01])(0[13578]|1[02])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|[12]\d|30)(0[13456789]|1[012])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|1\d|2[0-8])02((1[6-9]|[2-9]\d)?\d{2}))|(2902((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00))))$/;
      if (control.value.toString()) {
        if (!((control.value.match(RegExPattern)) && (control.value != ''))) {
          return { dataNotValid: true };
        }
        else
          return null;
      }

      return null;
    };
  }


  /**
   * Valida se o CPF é valido
  */
  static isValidCpf() {
    return (control: AbstractControl): Validators => {
      if (control.value) {
        const cpf = control.value.toString().replace(/\.|\-/g, '');

        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        if (cpf.length < 11) {
          return null;
        }

        for (i = 0; i < cpf.length - 1; i++) {
          if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpf.substring(0, 9);
          digits = cpf.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfNotValid: true };
          }
          numbers = cpf.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfNotValid: true };
          }
          return null;
        } else {
          return { cpfNotValid: true };
        }
      }
      return null;
    };
  }
}
