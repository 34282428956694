import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { LoginComponent } from '../login/login.component';
declare var $: any;


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, AfterViewInit { 

  constructor() {
  }
  ngAfterViewInit() {

  }
  ngOnInit() {
  }

  public static ShowHeaderInput(target: string) {

    if (target == 'home' || target == 'dashboard') {
      $("#searchBar").css('visibility', 'visible');
      $("#searchBar").addClass('input');
    }
    else if (target == 'vitrine' || target == 'voucher' || target == 'comparacaoPreco') {
      $("#searchBar").css('visibility', 'visible');
      $("#searchBar").removeClass('input');
    }
    else {
      $("#searchBar").css('visibility', 'hidden');
    }

  }

}
