import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { HomeService } from '../../service/home/home.service';
import { State } from '../../model/state';
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UsuarioService } from '../../service/usuario/usuario.service';
import { VoucherService } from '../../service/voucher/voucher.service';
import { Voucher } from '../../model/voucher';
import { moment } from 'ngx-bootstrap/chronos/test/chain';
import { of, Observable } from 'rxjs';
import { User } from '../../model/user';
import { AuthenticationService } from '../../service/authentication/authentication.service';
import { DataService } from '../../service/data/data.service';
import { Beneficiary } from '../../model/beneficiary';
import { ModalAlertService } from '../../modules/modals/alert-window';
import { UtilService } from '../../service/util/util.service';
import { LayoutComponent } from '../layout/layout.component';
import { debounceTime } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  periodInvalid: boolean = false;
  moment = moment;
  user: User = new User();
  beneficiary: Beneficiary = new Beneficiary();
  cpf: string;
  mobilePhoneNumber: string;
  dataInicio: any;
  dataFim: any;
  voucherList: Voucher[] = [];
  collection = { count: 0, data: [] };
  totalItemsPage: number = 3;
  total: number = 0;
  page: number = 1;
  asyncVoucher: Observable<Voucher[]>;
  vouchersAcumulados: any;
  usado: boolean = false;
  todos: boolean = false;
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
  cpfPattern = "^[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}$";
  passwordPattern = "/([A-Za-z]{1,}).*(\d{1,}).*|(\d{1,}).*([A-Za-z]{1,}).*/";

  state$: Observable<State>;

  public static states: State[] = [];
  modalRef: BsModalRef;

  perfilFormGroup = new FormGroup({
    cpf: new FormControl('', [Validators.required, Validators.pattern(this.cpfPattern), GenericValidator.isValidCpf()]),
    name: new FormControl('', [Validators.required, Validators.pattern("^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$")]),
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    mobilePhoneNumber: new FormControl('', [Validators.minLength(13)]),
    password: new FormControl('', [Validators.required, Validators.pattern(this.passwordPattern)]),
    repeatPassword: new FormControl('', [Validators.required]),
  });

  filtroDashboardFormGroup = new FormGroup({
    dataInicio: new FormControl('', [Validators.required]),
    dataFim: new FormControl('', [Validators.required])
  });

  constructor(
    private modalService: BsModalService,
    private homeService: HomeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private usuarioService: UsuarioService,
    private voucherService: VoucherService,
    private authenticationService: AuthenticationService,
    private dataService: DataService,
    private modalAlert: ModalAlertService,
    private utilService: UtilService,
    private stateStore: Store<State>
  ) {
    this.state$ = stateStore.pipe(select('state'))
  }

  ngOnInit() {

    $(
      function () {
        var options = $.extend({},
          $.datepicker.regional["pt"], {
          dateFormat: "dd/mm/yy",
          changeMonth: true,
          changeYear: true,
          highlightWeek: true,
          maxDate: new Date()
        }
        );

        $(".data").datepicker(options);

      }
    );

    LayoutComponent.ShowHeaderInput('dashboard');

    this.HideForm();
    let user = new User();
    this.authenticationService.getCurrentUser().subscribe(us => user = us);
    this.user = user;
    this.mobilePhoneNumber = this.formatarMobilePhoneNumber(user.mobilePhoneNumber);
    this.cpf = this.formatarCPF(user.cpf);

    this.dataInicio = moment().subtract(14, "days").format('DD/MM/YYYY');
    this.dataFim = moment().format('DD/MM/YYYY');

    //this.LoadInformacoesUsuario();
    //this.LoadInformacoesVoucher(1, true);
  }

  ngAfterViewInit() {
    this.LoadInformacoesUsuario();
    this.LoadInformacoesVoucher(1, true);

  }
  public HideForm() {
    $("#divFormulario").hide();
  }

  onUpdate() {

    this.authenticationService.getCurrentBeneficiary().subscribe(b => this.beneficiary = b);

    this.beneficiary.name = this.user.name;
    this.beneficiary.email1 = this.user.email;
    this.beneficiary.user = this.user;

    if (this.mobilePhoneNumber != null) {
      this.beneficiary.user.mobilePhoneNumber = this.mobilePhoneNumber.replace(/\(|\)|\s/g, '');
      this.beneficiary.mobilePhoneNumber = this.beneficiary.user.mobilePhoneNumber;
    }   

    this.authenticationService.update(this.beneficiary).then((res: any) => {

      if (res.success) {
        let token;
        this.authenticationService.getCurrentToken().subscribe(t => token = t);        

        this.authenticationService.storeData(this.user, token, res.data);
        
        this.resetDetail();
        this.openModalSuccess();
      }
    });

  }

  openModalSuccess() {
    this.modalAlert.show('Alteração de cadastro', 'Cadastro alterado com sucesso!');
  }

  editarClick(event: Event) {
    $("#divDetalhes").hide();
    $("#divFormulario").show();
  }

  resetDetail() {
    $("#divFormulario").hide();
    $("#divDetalhes").show();
  }

  searchClick(event: Event) {
    var pesquisa = $("#search").val();
    var estado;

    this.state$.subscribe((state) => {
      estado = state.name
    });

    this.router.navigate([`/vitrine/${pesquisa}/${estado}`]);
  }

  public cssObrigatorio(nomecampo, formulario) {
    if (formulario == 'filtroDashboard') {
      if (this.filtroDashboardFormGroup.controls[nomecampo].invalid && (this.filtroDashboardFormGroup.controls[nomecampo].touched || this.filtroDashboardFormGroup.controls[nomecampo].dirty)) {
        $("#" + nomecampo).css("border-color", "#a94442");
      }
      if (this.filtroDashboardFormGroup.controls[nomecampo].valid) {
        $("#" + nomecampo).css("border-color", "#003a75");
      }
    }
    else {
      if (this.perfilFormGroup.controls[nomecampo].invalid && (this.perfilFormGroup.controls[nomecampo].touched || this.perfilFormGroup.controls[nomecampo].dirty)) {
        $("#" + nomecampo).css("border-color", "#a94442");
      }
      if (this.perfilFormGroup.controls[nomecampo].valid) {
        $("#" + nomecampo).css("border-color", "#003a75");
      }
    }
  }

  public ativaTooltipSenha(component) {

    var ativa = false;

    var senhaOriginal = this.perfilFormGroup.controls["password"].value;
    var senhaRepetida = this.perfilFormGroup.controls["repeatPassword"].value;

    if (senhaOriginal != "" && senhaRepetida != "") {
      if (senhaOriginal != senhaRepetida) {
        ativa = true;
      }
    }
    this.ativaTooltip(ativa, component);
  }

  public ativaTooltipObrigatorio(nomecampo, component, formulario) {
    if (formulario == 'filtroDashboard') {
      var ativa = this.filtroDashboardFormGroup.controls[nomecampo].invalid;
      var campoObrigatorioerror = false;
      if (ativa) {
        campoObrigatorioerror = this.filtroDashboardFormGroup.controls[nomecampo].errors.required;
        campoObrigatorioerror = (campoObrigatorioerror !== null && campoObrigatorioerror) ? true : false;
      }

      ativa = ativa && campoObrigatorioerror;

      this.ativaTooltip(ativa, component);
    }
    else {
      var ativa = this.perfilFormGroup.controls[nomecampo].invalid;
      var campoObrigatorioerror = false;
      if (ativa) {
        campoObrigatorioerror = this.perfilFormGroup.controls[nomecampo].errors.required;
        campoObrigatorioerror = (campoObrigatorioerror !== null && campoObrigatorioerror) ? true : false;
      }

      ativa = ativa && campoObrigatorioerror;

      this.ativaTooltip(ativa, component);
    }
  }

  public ativaTooltipcampos(nomecampo, component, formulario) {
    if (formulario == 'filtroDashboard') {

      var ativa = this.filtroDashboardFormGroup.controls[nomecampo].invalid;
      var cpfinvalido = false;
      var dataInvalida = false;
      var patterninvalido = false;
      if (ativa) {
        cpfinvalido = this.filtroDashboardFormGroup.controls[nomecampo].errors.cpfNotValid;
        dataInvalida = this.filtroDashboardFormGroup.controls[nomecampo].errors.dataNotValid;
        patterninvalido = this.filtroDashboardFormGroup.controls[nomecampo].errors.pattern;
        cpfinvalido = (cpfinvalido !== null && cpfinvalido) ? true : false;
        patterninvalido = (patterninvalido !== null && patterninvalido) ? true : false;
      }
    }
    else {
      var ativa = this.perfilFormGroup.controls[nomecampo].invalid;
      var patterninvalido = false;
      if (ativa) {
        patterninvalido = this.perfilFormGroup.controls[nomecampo].errors.pattern;
        patterninvalido = (patterninvalido !== null && patterninvalido) ? true : false;
      }
    }
    ativa = ativa || patterninvalido;

    this.ativaTooltip(ativa, component);
  }

  public ativaTooltip(ativa, component) {

    var acao1 = ativa ? 'enable' : 'hide';
    var acao2 = ativa ? 'show' : 'disable';
    $(component).tooltip(acao1);
    $(component).tooltip(acao2);
  }

  public LoadInformacoesUsuario() {
    this.activatedRoute.params.subscribe(param => {
      if (param.id) {
        this.usuarioService.GetVouchersAcumulados(param.id).subscribe((result: any) => {
          var sucesso = JSON.parse(result.result).success;
          if (sucesso) {
            var retorno = JSON.parse(result.result).data;
            this.vouchersAcumulados = retorno.discount.toString().replace('.', ',');
          }
          else {
            this.vouchersAcumulados = "0,00";
          }
        });

      }
    });
  }

  public RetornaOpcaoFiltro(usado: boolean, todos: boolean) {
    this.usado = usado;
    this.todos = todos;

    this.LoadInformacoesVoucher(1, false);
  }

  public LoadInformacoesVoucher(page: number, validado: boolean) {
    this.activatedRoute.params.subscribe(param => {
      if (param.id) {
        if (this.todos == true) {
          $("#todos").addClass('buttonSelecionado');
          $("#disponiveis").removeClass('buttonSelecionado');
          $("#usados").removeClass('buttonSelecionado');
        }
        else if (this.usado == true) {
          $("#usados").addClass('buttonSelecionado');
          $("#todos").removeClass('buttonSelecionado');
          $("#disponiveis").removeClass('buttonSelecionado');
        }
        else {
          $("#disponiveis").addClass('buttonSelecionado');
          $("#usados").removeClass('buttonSelecionado');
          $("#todos").removeClass('buttonSelecionado');
        }

        if (validado == false && (this.ValidPeriod() || ($("#dataInicio").val() == "" || $("#dataFim").val() == ""))) {
          this.modalAlert.show('', 'Período inválido');
          return false;
        }
        var dataInicial = this.retornaDataFormatada($("#dataInicio").val());
        var dataFinal = this.retornaDataFormatada($("#dataFim").val());       

        this.voucherService.ReadVouchers(param.id, this.totalItemsPage, page, this.usado, this.todos, dataInicial, dataFinal).subscribe((result: any) => {
          var sucesso = JSON.parse(result.result).success;

          if (sucesso) {
            var retorno = JSON.parse(result.result).data;
            var pagination = JSON.parse(result.result).pagination;
            this.total = pagination.totalItems;
            this.page = page;
            this.voucherList = retorno;

            this.collection.count = this.total;
            this.collection.data = this.voucherList;
          }
          else {
            this.total = 0;
            this.page = page;

            this.collection.count = this.total;
            this.collection.data = [];
          }

        });
      }
    });
  }

  retornaDataFormatada(data: string) {
    var retorno = "";

    if (data == "") {
      retorno = "null";
    }
    else {
      var arrayData = data.split("/");

      var dia = arrayData[0];
      var mes = arrayData[1];
      var ano = arrayData[2];

      retorno = ano + "-" + mes + "-" + dia;
    }

    return retorno;
  }
  generatePDF(e: any, voucher: any) {
    e.preventDefault();
    this.utilService.generatePDF(this.user, voucher);
  }

  sendSms(e: any, authorizationId: string) {
    e.preventDefault();
    this.utilService.sendSms(this.user.mobilePhoneNumber, authorizationId);
  }

  sendEmail(e: any, voucher: any) {
    e.preventDefault();   
    this.utilService.sendEmail(this.user, voucher);
  }

  public formatarCPF(v) {
    if (v != undefined) {
      if (v.length <= 14) {
        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        return v;
      }
    }
  }

  public formatarMobilePhoneNumber(v) {
    v = v.replace(/\D/g, "");             
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); 
    return v;
  }

  ValidPeriod() {
      var inicio = $("#dataInicio").val();
      var fim = $("#dataFim").val();

      if (inicio && fim) {

        var dataIni = new Date(inicio.split("/")[2], inicio.split("/")[1], inicio.split("/")[0]);
        var dataFim = new Date(fim.split("/")[2], fim.split("/")[1], fim.split("/")[0]);

        if (dataFim < dataIni) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
  }
}
export class GenericValidator {
  constructor() { }

  /**
  * Valida se a data é valida
  */
  static validateDate() {
    return (control: AbstractControl): Validators => {
      var RegExPattern = /^((((0?[1-9]|[12]\d|3[01])[\.\-\/](0?[13578]|1[02])      [\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|[12]\d|30)[\.\-\/](0?[13456789]|1[012])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|1\d|2[0-8])[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|(29[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00)))|(((0[1-9]|[12]\d|3[01])(0[13578]|1[02])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|[12]\d|30)(0[13456789]|1[012])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|1\d|2[0-8])02((1[6-9]|[2-9]\d)?\d{2}))|(2902((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00))))$/;
      if (control.value.toString()) {
        if (!((control.value.match(RegExPattern)) && (control.value != ''))) {
          return { dataNotValid: true };
        }
        else
          return null;
      }

      return null;
    };
  }


  /**
   * Valida se o CPF é valido
  */
  static isValidCpf() {
    return (control: AbstractControl): Validators => {
      const cpf = control.value.toString().replace(/\.|\-/g, '');
      console.log(cpf);
      if (cpf) {
        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        if (cpf.length < 11) {
          return null;
        }

        for (i = 0; i < cpf.length - 1; i++) {
          if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpf.substring(0, 9);
          digits = cpf.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfNotValid: true };
          }
          numbers = cpf.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfNotValid: true };
          }
          return null;
        } else {
          return { cpfNotValid: true };
        }
      }
      return null;
    };
  }
}


