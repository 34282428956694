import { BeneficiaryActionTypes } from "../actions/beneficiary.actions";
import { Beneficiary } from "../model/beneficiary";
import { ActionModel } from "../model/action";

export const beneficiary = new Beneficiary();

export const beneficiaryReducer = (state = beneficiary, action: ActionModel) => {
  switch (action.type) {
    case BeneficiaryActionTypes.Add: {
      state = action.payload;
      return state;
    }
    case BeneficiaryActionTypes.Clear: {
      return new Beneficiary();
    }
    default:
      return state;
  }

}
