import { StateActionTypes } from "../actions/state.actions";
import { State } from "../model/state";
import { ActionModel } from "../model/action";

export const stateModel = new State();

export const stateReducer = (state = stateModel, action: ActionModel) => {
  switch (action.type) {
    case StateActionTypes.Add: {
      state = action.payload;
      return state;
    }
    case StateActionTypes.Clear: {
      return new State();
    }
    default:
      return state;
  }

}
