import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../../model/user';
import { Beneficiary } from '../../model/beneficiary';
import { PreAuthorization } from '../../model/pre-authorization';

@Injectable()
export class DataService {
  
  private user = new BehaviorSubject(new User());
  currentUser = this.user.asObservable();

  private beneficiary = new BehaviorSubject(new Beneficiary());
  currentBeneficiary = this.beneficiary.asObservable();

  private voucher = new BehaviorSubject(new PreAuthorization());
  currentVoucher = this.voucher.asObservable();

  constructor() { }

  changeUser(user: User) {
    console.log('Entrei no user data: ', new Date());
    this.user.next(user);
  }

  changeBeneficiary(benef: Beneficiary) {
    console.log('Entrei no user data: ', new Date());
    this.beneficiary.next(benef);
  }

  changeVoucher(voucher: PreAuthorization) {
    console.log('Entrei no user data: ', new Date());
    this.voucher.next(voucher);
  }
}
