import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { State } from '../../model/state';
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { Usuario } from '../../model/usuario';
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioService } from '../../service/usuario/usuario.service';
import { VoucherService } from '../../service/voucher/voucher.service';
import { Voucher } from '../../model/voucher';
import { DataService } from '../../service/data/data.service';
import { PreAuthorization } from '../../model/pre-authorization';
import { User } from '../../model/user';
import { UtilService } from '../../service/util/util.service';
import { ModalAlertService } from '../../modules/modals/alert-window';
import { moment } from 'ngx-bootstrap/chronos/test/chain';
import { NgxSpinnerService } from 'ngx-spinner';
import { LayoutComponent } from '../layout/layout.component';
import { Store, select } from '@ngrx/store';
import { Beneficiary } from '../../model/beneficiary';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthenticationService } from '../../service/authentication/authentication.service';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})
export class VoucherComponent implements OnInit {
  moment = moment;
  user: User = new User();
  voucher: PreAuthorization = new PreAuthorization();

  beneficiary$ :Observable<Beneficiary>;

  public static states: State[] = [];
  modalRef: BsModalRef;

  constructor(
    private dataService: DataService,
    private utilService: UtilService,
    private modalAlert: ModalAlertService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private beneficiaryStore: Store<Beneficiary>,
    private voucherService: VoucherService,
    private authenticationService: AuthenticationService
  )
  {
    this.beneficiary$ = beneficiaryStore.pipe(select('beneficiary'))
  }

  ngOnInit() {
    LayoutComponent.ShowHeaderInput('voucher');
    this.authenticationService.getCurrentUser().subscribe(us => this.user = us);

    if (this.utilService.hasVoucher()) {

      var obj = this.utilService.voucherSelected(this.user.cpf);

      this.voucherService.CreateVoucher(obj).then((res: any) => {
        if (res.success) {

          this.dataService.changeVoucher(res.data);

          this.utilService.clearSelectedVoucher();

          this.LoadInformacoesVoucher();
        }
        else {
          this.utilService.OpenModalGenericAlert('Voucher', res.message);
        }
      });
    } else {
      this.LoadInformacoesVoucher();
    }
    
  }

  public LoadInformacoesVoucher() {

    this.dataService.currentVoucher.subscribe(result => this.voucher = result);    
    this.dataService.currentVoucher.subscribe(result => {
      this.voucher = result;
    });
  }

  private OnEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    this.spinner.show();
  }

  private hideLoader(): void {
    this.spinner.hide();
  }

  generatePDF(e: any) {
    e.preventDefault();   
    this.utilService.generatePDF(this.user, this.voucher);
  }

  sendSms(e: any, authorizationId: string) {
    e.preventDefault();    
      this.utilService.sendSms(this.user.mobilePhoneNumber, authorizationId); 
  }

  sendEmail(e: any, voucher: any) {
    e.preventDefault();
    this.utilService.sendEmail(this.user, voucher);
  }

}


