import { Component, OnInit, AfterViewInit } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { HomeService } from "../../service/home/home.service";
import { State } from "../../model/state";
import { Router, ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { AddState } from "../../actions/state.actions";
import { Observable ,  pipe, combineLatest } from "rxjs";
import { delay, debounceTime } from "rxjs/operators";
import { LayoutComponent } from "../layout/layout.component";

declare var $: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit, AfterViewInit {
  public static states: State[] = [];
  modalRef: BsModalRef;
  selectedState: State;
  state$: Observable<State>;

  constructor(
    private modalService: BsModalService,
    private homeService: HomeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private stateStore: Store<State>
  ) {
    this.state$ = this.stateStore.pipe(select("state"));
    const $lastState = this.state$.subscribe((state) => {
      this.loadState(state)
      
    });
  }

  ngAfterViewInit() {}

  loadState(state) {
    this.homeService.getState(null).then((res: any) => {
      if (res) {
        HomeComponent.states = JSON.parse(res.result).data;
      }
      if ((state && !state.name) && ($('.modalState').length == 0)) this.openModalState();
    });
    //this.LoadSearch();
  }

  ngOnInit() {
    this.state$.take(1).toPromise();
    LayoutComponent.ShowHeaderInput("home");
    this.LoadSearch();
  }

  public LoadSearch() {
    this.activatedRoute.params.subscribe((param) => {
      if (param.termoPesquisa) {
        $("#search").val(param.termoPesquisa);
      }
    });
  }

  searchClick(event: Event) {
    var pesquisa = $("#search").val();
    var estado;

    this.state$.subscribe((state) => {
      estado = state.name
    });

    this.router.navigate([`/vitrine/${pesquisa}/${estado}`]);
  }

  openModalState() {
    const initialState = {
      title: "QUAL ESTADO VOCÊ ESTÁ?",
      message: "",
      closeBtnName: "Fechar",
    };

    this.modalRef = this.modalService.show(ModalContentStateComponent, {
      initialState,
      class: "modal-sm",
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.modalRef.content.closeBtnName = "Close";
  }
}

@Component({
  selector: "modal-content",
  template: `
    <div class="modal-header modalState">
      <h4 class="modal-title pull-left" id="titleModal">{{ title }}</h4>
    </div>
    <div class="modal-body">
      <form #stateForm="ngForm">
        <div class="row" align="center">
          <select id="stateSelect" (change)="onChange($event.target.value)">
            <option [ngValue]="0">Escolha um estado</option>
            <option *ngFor="let state of states" [value]="state.stateCode">{{
              state.name
            }}</option>
          </select>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="row" align="center">
        <button
          type="submit"
          class="btn btn-cont btnModalHome"
          (click)="onSubmit()"
        >
          Continuar
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .btn-cont {
        background: #075e9c;
        color: #fff !important;
        padding: 5px 20px;
        border-radius: 20px;
        text-transform: uppercase;
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
      }

      #stateSelect {
        webkit-appearance: none !important; /* Remove estilo padrão do Chrome */
        border-top: none !important;
        border-right: none !important;
        border-bottom: solid 1px #075e9c !important;
        border-left: none !important;
        color: #075e9c;
      }
      #stateSelect option:hover {
        background-color: #075e9c !important;
      }
      #titleModal {
        color: #075e9c;
      }
    `,
  ],
})
export class ModalContentStateComponent {
  modalRef: BsModalRef;
  public static stateSelected: string;
  states: State[] = HomeComponent.states;

  constructor(
    public bsModalRef: BsModalRef,
    private stateStore: Store<State>
  ) {}

  onSubmit() {
    if (ModalContentStateComponent.stateSelected != undefined) {
      let selected = new State();
      selected.name = ModalContentStateComponent.stateSelected;

      this.stateStore.dispatch(AddState(selected));
      this.bsModalRef.hide();
    }
  }

  onChange(value: any) {
    ModalContentStateComponent.stateSelected = value;
  }
}
