import { Action } from '@ngrx/store';
import { Beneficiary } from '../model/beneficiary';

export enum BeneficiaryActionTypes {
  Add = 'ADD_BENEFICIARY',
  Clear = 'CLEAR_BENEFICIARY'
}

export const AddBeneficiary = (beneficiary: Beneficiary) => <Action>{ type: BeneficiaryActionTypes.Add, payload: beneficiary };
export const ClearBeneficiary = () => <Action>{ type: BeneficiaryActionTypes.Clear, payload: null };
