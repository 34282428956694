import { Component, OnInit, TemplateRef, HostListener } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ProductService } from '../../service/product/product.service';
import { Product } from '../../model/product';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { LayoutComponent } from '../layout/layout.component';
declare var $: any;

@Component({
  selector: 'app-vitrine',
  templateUrl: './vitrine.component.html',
  styleUrls: ['./vitrine.component.css']
})

export class VitrineComponent implements OnInit {
    math = Math;
    productsList: Product[] = [];
    totalItemsPage: number = 21;
    page: number = 1;
    total: number;
    innerWidth: number;
    public pesquisa: string;

  constructor(private modalService: BsModalService,
    private productService: ProductService,
    private activatedRoute: ActivatedRoute,
    private location: Location) { }

  ngOnInit() {
    LayoutComponent.ShowHeaderInput('vitrine');

    this.LoadProducts(this.page);
  }

  public LoadProducts(page: number) {
    this.activatedRoute.params.subscribe(param => {
      if (param.termoPesquisa && param.stateCode) {
        this.pesquisa = param.termoPesquisa;
        this.productService.ReadProducts(param.termoPesquisa, param.stateCode).subscribe((result: any) => {
        var sucesso = JSON.parse(result.result).success;

          if (sucesso) {
            var retorno = JSON.parse(result.result).data;
            this.productsList = retorno;
          }

          this.total = this.productsList.length;

        });
      }
    });
  }

  backPageClick() {
    this.location.back();
  }
}


