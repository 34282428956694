import { Action } from '@ngrx/store';
import { State } from '../model/state';

export enum StateActionTypes {
  Add = 'ADD_STATE',
  Clear = 'CLEAR_STATE'
}

export const AddState = (token: State) => <Action>{ type: StateActionTypes.Add, payload: token };
export const ClearState = () => <Action>{ type: StateActionTypes.Clear, payload: null };
