import { ProductDiscount } from "./product-discount";

export class Chain {
    chainId: number;
    name: string;
    uf: string;
    differentiatedDiscountByChain: Array<ProductDiscount>;
    differentiatedDiscountSku: Array<ProductDiscount>;
}

