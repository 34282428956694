import { Component, OnInit, TemplateRef } from '@angular/core';
import { ProductService } from '../../service/product/product.service';
import { Product } from '../../model/product';
import { Chain } from '../../model/chain';
import { ActivatedRoute, Router } from '@angular/router';
import { VoucherService } from '../../service/voucher/voucher.service';
import { PreAuthorization } from '../../model/pre-authorization';
import { DataService } from '../../service/data/data.service';
import { LayoutComponent } from '../layout/layout.component';
import { ModalAlertService } from '../../modules/modals/alert-window';
import { User } from '../../model/user';
import { Beneficiary } from '../../model/beneficiary';
import { AuthenticationService } from '../../service/authentication/authentication.service';
declare var $: any;

@Component({
  selector: 'app-comparacaoPreco',
  templateUrl: './comparacaoPreco.component.html',
  styleUrls: ['./comparacaoPreco.component.css']
})

export class ComparacaoPrecoComponent implements OnInit {
  product: Product = new Product();
  math = Math;
  chain: Chain = new Chain();
  public chains: any[];
  public static selectedVoucher: any;
  user: User = new User();
  beneficiary: Beneficiary = new Beneficiary();

  constructor(private productService: ProductService,
    private route: Router,
    private dataService: DataService,
    private voucherService: VoucherService,
    private activatedRoute: ActivatedRoute,
    private modalAlert: ModalAlertService,
    private authenticationService: AuthenticationService)
  { }

  ngOnInit() {
    LayoutComponent.ShowHeaderInput('comparacaoPreco');
    this.LoadPrice();
  }

  public LoadProduct() {
    this.activatedRoute.params.subscribe(param => {
      if (param.id) {
        this.productService.GetProduct(param.id).subscribe((result: any) => {
          if (result) {
            var retorno = JSON.parse(result.result).data;
            this.product = retorno[0];
          }
        });
      }
    });
  }

  public LoadPrice() {
    this.activatedRoute.params.subscribe(param => {
      if (param.id && param.stateCode) {
        this.productService.GetProductPrice(param.id, param.stateCode).subscribe((result: any) => {
          var retorno = result.data;
          this.product = retorno;
        });
      }
    });
  }

  public gerarVoucher(pmc, maximumDiscount) {

    var beneficiaryId;
    var paramId;
    this.activatedRoute.params.subscribe(param => {
      if (param.id && param.stateCode) {
        paramId = param.id;
      }
    });
    
    //this.authenticationService.getCurrentUser().subscribe(us => this.user = us);
    this.authenticationService.getCurrentBeneficiary().subscribe(b => this.beneficiary = b);

    var logado = (this.beneficiary.id != null) ? true : false;
    //caso logado
    if (logado) {

      var obj = {
        skuId: paramId,
        pmc: pmc,
        maximumDiscount: maximumDiscount,
        beneficiaryId: this.beneficiary.cpf
      };

      this.voucherService.CreateVoucher(obj).then((result: any) => {
        if (result != null && result.success) {          
          this.dataService.changeVoucher(result.data);
          this.route.navigate(['voucher']);
        }
        else {
          this.openModalVourcherError(result.message);
        }
      });
    } else {

      ComparacaoPrecoComponent.selectedVoucher = {
        hasSelectedVoucher: true,
        voucher: {
          id: paramId,
          pmc: pmc,
          maximumDiscount: maximumDiscount
        }
      };

      this.route.navigate(['login']);
    }
  }

  openModalVourcherError(message) {
    this.modalAlert.show('Alerta', message);
  }
}
