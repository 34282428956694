import { UserActionTypes } from "../actions/user.actions";
import { User } from "../model/user";
import { ActionModel } from "../model/action";

export const user = new User();

export const userReducer = (state = user, action: ActionModel) => {
  switch (action.type) {
    case UserActionTypes.Add: {
      state = action.payload;
      return state;
    }
    case UserActionTypes.Clear: {
      return new User();
    }
    default:
      return state;
  }

}
