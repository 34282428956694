import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './_guards/authGuard';
import { HomeComponent } from './pages/home/home.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { Routes, RouterModule } from '@angular/router';

//import { CupomComponent } from './pages/cupom/cupom.component';
import { AlterarSenhaComponent } from './pages/alterar-senha/alterar-senha.component';
import { EsqueciMinhaSenhaComponent } from './pages/esqueci-minha-senha/esqueci-minha-senha.component';
import { RegistreSeComponent } from './pages/registre-se/registre-se.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ComparacaoPrecoComponent } from './pages/comparacao-precos/comparacaoPreco.component';
import { VitrineComponent } from './pages/vitrine/vitrine.component';
import { VoucherComponent } from './pages/voucher/voucher.component';


const appRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,   
    children: [
      //{ path: 'home', component: HomeComponent, pathMatch: 'full' },
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'vitrine/:termoPesquisa/:stateCode', component: VitrineComponent },
      { path: 'compararPrecos/:id/:stateCode', component: ComparacaoPrecoComponent },
    ]
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'home/:termoPesquisa', component: HomeComponent },
  { path: 'login', component: LoginComponent},
  { path: 'alterarSenha', component: AlterarSenhaComponent },
  { path: 'alterarSenha/:hash', component: AlterarSenhaComponent },
  { path: 'esqueciMinhaSenha', component: EsqueciMinhaSenhaComponent },
  { path: 'registreSe/:id', component: RegistreSeComponent },
  { path: 'registreSe', component: RegistreSeComponent },
  {
    path: 'dashboard/:id',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'voucher',
    component: VoucherComponent,
    canActivate: [AuthGuard]
  }
];

export const routing = RouterModule.forRoot(appRoutes, { useHash: true });
