import { ItemsAuthorization } from "./items-authorization";

export class PreAuthorization {
  beneficiaryIdentifier: string;
  prescriptionScanning: number;
  authorizationId: number;
  expirationDate: Date;
  items: Array<ItemsAuthorization>;
  beneficiaries: Array<string>;
  code: number;
  storeId: number;
  storeSequenceId: number;
  message: string;
}

