import { TokenActionTypes } from "../actions/token.actions";
import { Token } from "../model/token";
import { ActionModel } from "../model/action";

export const token = new Token();

export const tokenReducer = (state = token, action: ActionModel) => {
  switch (action.type) {
    case TokenActionTypes.Add: {
      state = action.payload;
      return state;
    }
    case TokenActionTypes.Clear: {
      state = new Token();
      return state;
    }
    case TokenActionTypes.IsValid: {
      return {
        ...state,
        isValidToken: (state != null && Date.parse(state.expires) > Date.now())
      }
    }
    default:
      return state;
  }

}
