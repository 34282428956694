import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
    selector: 'modal-alert-component',
    template: '',
})
export class ModalAlertService {
    bsModalRef: BsModalRef;
    constructor(private modalService: BsModalService) { }

    show(title: string, message: string) {
        const initialState = {
            title: title,
            message: message
        };
        this.bsModalRef = this.modalService.show(ModalAlertComponent, { initialState });
        this.bsModalRef.content.closeBtnName = 'Close';
    }
}

@Component({
    selector: 'modal-content',
    template: `
    <div class="modal-header">
    <h4 class="modal-title text-center">{{title}}</h4>
  </div>
  <div class="modal-body text-center">
    <p>{{message}}</p>
    <button type="button" class="btn btn-default" (click)="confirm()">OK</button>
  </div>
    `
})
export class ModalAlertComponent {
    constructor(public bsModalRef: BsModalRef) { }

    confirm() {
        this.bsModalRef.hide();
    }
}