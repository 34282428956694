import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ModalConfirmContentComponent, ModalConfirmService } from "./confirm-window";
import { ModalAlertComponent, ModalAlertService } from "./alert-window";
import { ModalEnviaEmailComponent, ModalEnviaEmailService } from "./envia-email-window";
import { ModalGenericComponent, ModalGenenericService } from "./generic-window.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";


@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    declarations: [
        ModalConfirmContentComponent,
        ModalConfirmService,
        ModalAlertComponent,
        ModalAlertService,
        ModalEnviaEmailComponent,
      ModalEnviaEmailService,
      ModalGenericComponent,
      ModalGenenericService
    
    ],
  entryComponents: [ModalConfirmContentComponent, ModalAlertComponent, ModalEnviaEmailComponent, ModalGenericComponent],
  exports: [ModalConfirmContentComponent, ModalAlertComponent, ModalEnviaEmailComponent, ModalGenericComponent]
})
export class DiscountCouponWindowModule {

    static forRoot() {
        return {
          ngModule: DiscountCouponWindowModule,
          providers: [ModalConfirmService, ModalAlertService, ModalEnviaEmailService, ModalGenenericService]
        }

    }
}
