
import { Component, OnInit, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../service/authentication/authentication.service';
import { Router } from '@angular/router';
import { tap, delay } from 'rxjs/operators';
import { ModalEnviaEmailService } from '../../modules/modals/envia-email-window';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { User } from '../../model/user';
import { ModalGenenericService } from '../../modules/modals/generic-window.module';
import { ModalAlertService } from '../../modules/modals/alert-window';
import { ComparacaoPrecoComponent } from '../comparacao-precos/comparacaoPreco.component';
import { VoucherService } from '../../service/voucher/voucher.service';
import { LoginComponent } from '../login/login.component';
import { LayoutComponent } from '../layout/layout.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from '../../service/util/util.service';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './registre-se.component.html',
  styleUrls: ['./registre-se.component.css']
})
export class RegistreSeComponent implements OnInit {
  user: User = new User();
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
  cpfPattern = "^[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}$";
  passwordPattern = "/([A-Za-z]{1,}).*(\d{1,}).*|(\d{1,}).*([A-Za-z]{1,}).*/"; 
  showRedefinir: boolean;
  mensagemRedefinir: string;
  cpf: string;
  password: string;
  repeatPassword: string;
  hasCpf: boolean = false;
  mobilePhoneNumber: string;

  perfilFormGroup = new FormGroup({
    cpf: new FormControl('', [Validators.required, GenericValidator.isValidCpf()]),
    name: new FormControl('', [Validators.required, Validators.pattern("^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$")]),
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    mobilePhoneNumber: new FormControl('', [Validators.minLength(13)]),
    password: new FormControl('', [Validators.required, GenericValidator.validatePassword()]),
    repeatPassword: new FormControl('', [Validators.required]),
  });

  constructor(private authenticationService: AuthenticationService,       
    private route: Router,
    private utilService: UtilService,
    private modalGeneric: ModalGenenericService,
    private modalAlert: ModalAlertService,    
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    LayoutComponent.ShowHeaderInput('registre-se');   
  }

  onRegister(e: any) {
    e.preventDefault();

    this.user.cpf = this.cpf.replace(/\.|\-/g, '');
    this.user.password = this.password;
    this.user.login = this.user.cpf;

    if (this.mobilePhoneNumber)
    {
      this.user.mobilePhoneNumber = this.mobilePhoneNumber.replace(/\(|\)|\s/g, '');
    }
    this.authenticationService.insert(this.user).then((res: any) => {     
      if (res.success) {

        this.authenticationService.login(this.user).then((response: any) => {

          var resultAuth = JSON.parse(response.result);

          const { token, user } = resultAuth.data;
          //Store user data login
          this.authenticationService.storeData(user, token, res.data);

          if (this.utilService.hasVoucher()) {
            this.route.navigate(['voucher']);
          }
          else {
            this.route.navigate(['dashboard', res.data.id]);
            return true;
          }

        });
      }
      else if (!res.success && res.messages[0].message.indexOf('cadastrado') > -1) {
        this.hasCpf = true;
        this.ativaTooltip('cpf', $('#hasCpfRegister'));
        this.cssObrigatorio('cpf', true);
        return false;
       
      }
      else {

        this.openModalAlert();
        
      }

    },
      (err: any) => {        
        console.error(err);
        this.openModalAlert();
      });

  }

  openModalAlert() {
    this.modalAlert.show('Alerta', 'Ocorreu um erro na solicitação tente novamente mais tarde!');
  }

  openModalVourcherError(message) {
    this.modalAlert.show('Alerta', message);
  }

  public cssObrigatorio(nomecampo, forceInvalid = false) {
    if (this.perfilFormGroup.controls[nomecampo].invalid && (this.perfilFormGroup.controls[nomecampo].touched || this.perfilFormGroup.controls[nomecampo].dirty) || forceInvalid) {
      $("#" + nomecampo).css("border-color", "#a94442");
    }
    if (this.perfilFormGroup.controls[nomecampo].valid && !forceInvalid) {
      $("#" + nomecampo).css("border-color", "#003a75");
    }
  }
 
  public ativaTooltipSenha(component) {

    var ativa = false;

    var senhaOriginal = this.perfilFormGroup.controls["password"].value;
    var senhaRepetida = this.perfilFormGroup.controls["repeatPassword"].value;

    if (senhaOriginal != "" && senhaRepetida != "") {
      if (senhaOriginal != senhaRepetida) {
        ativa = true;
      }
    }
    
    this.ativaTooltip(ativa, component);
  }

  public ativaTooltipObrigatorio(nomecampo, component) {

    var ativa = this.perfilFormGroup.controls[nomecampo].invalid;
    var campoObrigatorioerror = false;
    if (ativa) {
      campoObrigatorioerror = this.perfilFormGroup.controls[nomecampo].errors.required;
      campoObrigatorioerror = (campoObrigatorioerror !== null && campoObrigatorioerror) ? true : false;
    }

    ativa = ativa && campoObrigatorioerror;
   

    this.ativaTooltip(ativa, component);
  }

  public ativaTooltipcampos(nomecampo, component) {

    var ativa = this.perfilFormGroup.controls[nomecampo].invalid;
    var cpfinvalido = false;
    var patterninvalido = false;
    var senhainvalida = false;
    if (ativa) {
      //cpfinvalido = this.perfilFormGroup.controls[nomecampo].errors.cpfNotValid;
      senhainvalida = this.perfilFormGroup.controls[nomecampo].errors.passwordNotValid;
      patterninvalido = this.perfilFormGroup.controls[nomecampo].errors.pattern;
     // cpfinvalido = (cpfinvalido !== null && cpfinvalido) ? true : false;
      patterninvalido = (patterninvalido !== null && patterninvalido) ? true : false;
    }

    ativa = ativa && (patterninvalido || senhainvalida);

    var change = false;
    if (this.cpf != null && (this.user.cpf != null || this.user.cpf != undefined)) {
      change = (this.cpf.replace(/\.|\-/g, '') != this.user.cpf);
    }

    if (ativa || change) {     
      this.hasCpf = false;      
      this.ativaTooltip(this.hasCpf, $('#hasCpfRegister'));
    }

    this.ativaTooltip(ativa, component);
  }

  public ativaTooltip(ativa, component) {

    var acao1 = ativa ? 'enable' : 'hide';
    var acao2 = ativa ? 'show' : 'disable';
    $(component).tooltip(acao1);
    $(component).tooltip(acao2);
  }
  
}



export class GenericValidator {
  constructor() { }

  /**
  * Valida se a data é valida
  */
  static validateDate() {
    return (control: AbstractControl): Validators => {
      var RegExPattern = /^((((0?[1-9]|[12]\d|3[01])[\.\-\/](0?[13578]|1[02])      [\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|[12]\d|30)[\.\-\/](0?[13456789]|1[012])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|1\d|2[0-8])[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|(29[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00)))|(((0[1-9]|[12]\d|3[01])(0[13578]|1[02])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|[12]\d|30)(0[13456789]|1[012])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|1\d|2[0-8])02((1[6-9]|[2-9]\d)?\d{2}))|(2902((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00))))$/;
      if (control.value.toString()) {
        if (!((control.value.match(RegExPattern)) && (control.value != ''))) {
          return { dataNotValid: true };
        }
        else
          return null;
      }

      return null;
    };
  }


  /**
   * Valida se o CPF é valido
  */
  static isValidCpf() {
    return (control: AbstractControl): Validators => {      
      if (control.value) {
        const cpf = control.value.toString().replace(/\.|\-/g, '');
        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        if (cpf.length < 11) {
          return null;
        }

        for (i = 0; i < cpf.length - 1; i++) {
          if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpf.substring(0, 9);
          digits = cpf.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfNotValid: true };
          }
          numbers = cpf.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfNotValid: true };
          }
          return null;
        } else {
          return { cpfNotValid: true };
        }
      }
      return null;
    };
  }

  public static passwordPattern = /([A-Za-z]{1,}).*(\d{1,}).*|(\d{1,}).*([A-Za-z]{1,}).*/;
  static validatePassword() {
    return (control: AbstractControl): Validators => {      
      let result = {};

      if (control.value != null) {
        const password = control.value.toString().replace(/\.|\-/g, '');
        if (password && password.length > 0) {
          if (!password.match(this.passwordPattern) || password.length < 8) {
            return { passwordNotValid: true };
          }
        }
      }
      return null;

    }
  }

}
