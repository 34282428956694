import { User } from "./user";
import { System } from "./system";
import { Card } from "./card";

export class Beneficiary { 

  id: number;
  benefitId: number;
  name: string;
  startDate: Date;
  endDate: Date;
  cardholderNumber: string;
  mainCardholderNumber: string; 
  birthDate: Date; 
  cpf: string; 
  lastName: string;
  email1: string;  
  mobilePhoneNumber: string;
  transferStatus: string;       
  gender: string; 
  registrationType: number;
  updatedInfo: string;
  dataWarehouseDate: Date;
  dataWarehouseError: Date; 
  addressType: string;
  addressLine: string;
  addressNumber: string;
  addressComplement: string;
  addressNeighborhood: string;
  addressCity: string; 
  addressState: string; 
  addressZipCode: string;
  blocked: boolean; 
  limit: number; 
  limitDate: Date;
  cardGeneratedDate: Date; 
  companyRegister: string; 
  dependentDegree: string; 
  structureCode: string; 
  cardDisplayName: string; 
  status: number;  
  beneficiaryHolderId: number;  
  firedControl: string; 
  updatedUser: string; 
  updatedDate: Date; 
  createdDate: Date; 
  internalUpdatedDate: string; 
  documentNumber: string; 
  documentIssuer: string;   
  migrationStatus: string; 
  migrationDate: Date; 
  reactivationDate: Date; 
  allowanceRangeId: number; 
  email2: string; 
  splashScreenViewed: number;  
  phoneNumber1: string; 
  phoneNumber2: string;   
  personId: number;
  //cards
  cards: Card[];
  user: User;
  system: System;

}
