import { Action } from '@ngrx/store';
import { Token } from '../model/token';

export enum TokenActionTypes {
  Add = 'ADD_TOKEN',
  Clear = 'CLEAR_TOKEN',
  IsValid = 'IS_VALID_TOKEN'
}

export const AddToken = (token: Token) => <Action>{ type: TokenActionTypes.Add, payload: token };
export const ClearToken = () => <Action>{ type: TokenActionTypes.Clear, payload: null };
export const IsValidToken = () => <Action>{ type: TokenActionTypes.IsValid, payload: null };
