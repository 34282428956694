
import { Injectable } from '@angular/core';
import { CustomHttpClient, IRequestOptions } from './../../providers/CustomHttpClient';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../model/user';
import { Beneficiary } from '../../model/beneficiary';
import { baseApiUrl } from '../../app.config';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalAlertService } from '../../modules/modals/alert-window';
import { ComparacaoPrecoComponent } from '../../pages/comparacao-precos/comparacaoPreco.component';
import { ModalGenenericService } from '../../modules/modals/generic-window.module';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private http: CustomHttpClient, private spinner: NgxSpinnerService,
    private modalAlert: ModalAlertService,
    private modalGeneric: ModalGenenericService) { }

  private OnEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    this.spinner.show();
  }

  private hideLoader(): void {
    this.spinner.hide();
  }

  openModalRedirect(title: string, message: string, route: string) {
    this.modalGeneric.show(title, message, route);
  }

  OpenModalGenericAlert(title: string, message: string) {
    this.modalAlert.show(title, message);
  }

  OpenModalWithoutPhoneNumber() {
    this.modalAlert.show('Sms', 'Atualize o seu cadastro com o número de telefone para recebimento do voucher via sms!');
  }

  openModalSuccess(typeMessage: number) {
    if (typeMessage == 1)
      this.modalAlert.show('Sms', 'Sms enviado com sucesso, em instantes você receberá a mensagem no seu celular!');
    else
      this.modalAlert.show('E-mail', 'E-mail enviado com sucesso, em instantes você receberá a mensagem!');
  }

  openModalError(typeMessage: number) {
    if (typeMessage == 1)
      this.modalAlert.show('Sms', 'Ocorreu um erro ao enviar o sms, tente novamente mais tarde!');
    else
      this.modalAlert.show('E-mail', 'Ocorreu um erro ao enviar o e-mail, tente novamente mais tarde!');
  }

  sendSms(mobilePhoneNumber: string, authorizationId: string) {
    this.showLoader();

    if (!(mobilePhoneNumber.length > 0)) {
      this.OpenModalWithoutPhoneNumber();
      return;
    }

    var obj = {};

    obj = {
      MobilePhoneNumber: mobilePhoneNumber.replace(/\(|\)|\s/g, ''),
      AuthorizationId: authorizationId
    };

    this.sms(obj).then((res: any) => {
      var result = JSON.parse(res.result);

      this.OnEnd();

      if (result.success) {
        this.openModalSuccess(1);
      }
      else {
        this.openModalError(1);
      }

    });

  }

  sendEmail(user: any, voucher: any) {
    this.showLoader();

    var obj = {};

    obj = {
      Name: user.name,
      Email: user.email,
      ProductName: voucher.items[0].productName.replace('+', ' ').replace('/', ' '),
      Price: this.valuePrice(voucher).toString().replace('.', ','),
      AuthorizationId: this.valueAuthorizationId(voucher)
    };

    this.email(obj).then((res: any) => {
      var result = JSON.parse(res.result);

      this.OnEnd();

      if (result.success) {
        this.openModalSuccess(2);
      }
      else {
        this.openModalError(2);
      }
    });
  }

  generatePDF(user: any, voucher: any) {
    this.showLoader();
    var obj = {};
    //Alterar

    obj = {
      Name: user.name,
      Email: user.email,
      ProductName: voucher.items[0].productName.replace('+', ' ').replace('/', ' '),
      Price: this.valuePrice(voucher).toString().replace('.', ','),
      AuthorizationId: this.valueAuthorizationId(voucher)
    };

    var json = JSON.stringify(obj);

    this.gerarPDF(json);

    this.OnEnd();
  }

  valuePrice(voucher: any) {

    return (parseFloat(voucher.items[0].salePrice) > 0) ? voucher.items[0].salePrice : voucher.items[0].referencePrice;
  }

  valueAuthorizationId(voucher: any) {
    return (voucher.authorizationId != undefined) ? voucher.authorizationId : voucher.externalId;
  }

  sms(obj: any) {

    return new Promise((resolve, reject) => {
      this.http.Post(`${baseApiUrl}api/util/SendSms`, obj).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  email(obj: any) {

    return new Promise((resolve, reject) => {
      this.http.Post(`${baseApiUrl}api/util/SendEmail`, obj).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  gerarPDF(json: any) {
    window.open(`${baseApiUrl}api/util/GerarPDF/` + json);
  }

  hasVoucher() {
    if (ComparacaoPrecoComponent.selectedVoucher != undefined) {
      if (ComparacaoPrecoComponent.selectedVoucher.hasSelectedVoucher) {
        return true;
      }
    }
    return false;
  }

  clearSelectedVoucher() {
    ComparacaoPrecoComponent.selectedVoucher = null;
  }

  voucherSelected(cpf: any) {
    var voucher = ComparacaoPrecoComponent.selectedVoucher.voucher;

    var obj = {
      skuId: voucher.id,
      pmc: voucher.pmc,
      maximumDiscount: voucher.maximumDiscount,
      beneficiaryId: cpf
    };

    return obj;
      
  }
}
