import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
    selector: 'modal-confirm-component',
    template: '',
})
export class ModalConfirmService {
    bsModalRef: BsModalRef;
    constructor(private modalService: BsModalService) { }

    show(title: string, message: string, confirmClicked: Function, declineClicked?: Function) {
        const initialState = {
            title: title,
            message: message,
            confirm: () => { this.bsModalRef.hide(); if (confirmClicked) confirmClicked(); },
            decline: () => { this.bsModalRef.hide(); if (declineClicked) declineClicked(); }
        };
        this.bsModalRef = this.modalService.show(ModalConfirmContentComponent, { initialState });
        this.bsModalRef.content.closeBtnName = 'Close';
    }
}

@Component({
    selector: 'modal-content',
    template: `
    <div class="modal-header">
    <h4 class="modal-title text-center">{{title}}</h4>
  </div>
  <div class="modal-body text-center">
    <p>{{message}}</p>
    <button type="button" class="btn btn-default" (click)="confirm()" >Sim</button>
    <button type="button" class="btn btn-primary" (click)="decline()" >Não</button>
  </div>
    `
})
export class ModalConfirmContentComponent {
    constructor(public bsModalRef: BsModalRef) { }
} 