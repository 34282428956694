
import { Injectable } from '@angular/core';
import { CustomHttpClient, IRequestOptions } from './../../providers/CustomHttpClient';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Product } from '../../model/product';
import { Router } from '@angular/router';
import { baseApiUrl } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  router: Router;

  constructor(private http: CustomHttpClient, router: Router)
  {
    this.router = router;
  }

  public GetVouchersAcumulados(id: number) {
    return this.http.Get(`${baseApiUrl}api/usuario/GetVouchersAcumulados/` + id);
  }

  public GetUsuario(id: number) {
    return this.http.Get(`${baseApiUrl}api/usuario/GetUsuario/` + id);
  }
 
}
