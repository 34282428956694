
import { Injectable } from '@angular/core';
import { CustomHttpClient, IRequestOptions } from './../../providers/CustomHttpClient';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Voucher } from '../../model/voucher';
import { Router } from '@angular/router';
import { baseApiUrl } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {
  router: Router;

  constructor(private http: CustomHttpClient, router: Router)
  {
    this.router = router;
  }

  public ReadVouchers(id: number, itensPerPage: number, page: number, usado: boolean, todos: boolean, fromDate: any, toDate: any) {
    return this.http.Get(`${baseApiUrl}api/voucher/ReadVouchers/${id}/${itensPerPage}/${page}/${usado}/${todos}/${fromDate}/${toDate}`);
  }

  public GetVoucher(id: number) {
    return this.http.Get(`${baseApiUrl}api/voucher/GetVoucher/` + id);
  }

  public CreateVoucher(obj: any) {
    return new Promise((resolve, reject) => {
      this.http.Post(`${baseApiUrl}api/voucher/CreateVoucher`, obj).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
    //return this.http.Get("api/voucher/CreateVoucher/" + skuId + "/" + pmc + "/" + maximumDiscount + "/" + beneficiaryId);
  }

}
