import { Action } from '@ngrx/store';
import { User } from '../model/user';

export enum UserActionTypes {
  Add = 'ADD_USER',
  Clear = 'CLEAR_USER'
}

export const AddUser = (user: User) => <Action>{ type: UserActionTypes.Add, payload: user };
export const ClearUser = () => <Action>{ type: UserActionTypes.Clear, payload: null };

