import { System } from "./system";
import { SecurityKeyUser } from "./securityKeyUser";

export class User {

  id: number;
  login: string;
  password: string;
  mobilePhoneNumber: string;
  email: string;
  cpf: string;
  name: string;
  lastName: string;
  startDate: Date;
  endDate: Date;
  phoneNumber: number;
  pingDate: Date;
  gender: string;
  birthDate: Date;
  status: number;
  type: number;
  requireNewPassword: boolean; 
  rg: string;
  addressLine: string;
  addressZipCode: string;
  addressNeighborhood: string;
  addressComplement: string;
  addressCity: string;
  addressState: string;
  loginAttemptsNumber: number;
  systems: System[];
  securityKey: SecurityKeyUser
}
